<template>
  <div class="container">
    <el-card style="margin-top: 20px; width: 100%">
      <el-form ref="form" inline size="small" :model="searchForm">
        <el-form-item
          :label="`${$t('service_fee_query_title1')}:`"
          prop="status"
        >
          <el-select
            v-model="searchForm.status"
            @change="handleStatusChange"
            :placeholder="$t('COMMON_ERR4')"
            clearable
          >
            <el-option
              :label="$t('service_fee_query_title2')"
              value="0"
            ></el-option>
            <el-option
              :label="$t('service_fee_query_title3')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('service_fee_query_title4')"
              value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${$t('contract_query_title2')}:`" prop="date">
          <el-date-picker
            clearable
            @change="handleDateChange"
            v-model="searchForm.date"
            type="daterange"
            :range-separator="$t('incoming_company_date_placeholder1')"
            :start-placeholder="$t('incoming_company_date_placeholder2')"
            :end-placeholder="$t('incoming_company_date_placeholder3')"
            valueFormat="yyyy-MM-dd"
            format="yyyy-MM-dd"
            style="width: 400px"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <!--      table-->
      <el-table
        :data="tableData"
        style="width: 100%"
        size="small"
        v-loading="loading"
      >
        <el-table-column
          prop="companyName"
          fixed
          width="180"
          :label="$t('service_fee_query_title5')"
        />
        <el-table-column
          prop="exDealType"
          :label="$t('service_fee_query_title6')"
          width="180"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.exDealType === '1'
                  ? $t('initlate_exchange_text5')
                  : $t('contract_query_title5')
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ccyPair"
          :label="$t('contract_query_title6')"
          width="180"
        />
        <el-table-column
          prop="txnData"
          :label="$t('home_page_title19')"
          width="180"
        />
        <el-table-column
          prop="contraData"
          :label="$t('home_page_title20')"
          width="180"
        />
        <el-table-column
          prop="rate"
          :label="$t('home_page_title21')"
          width="180"
        />
        <el-table-column
          prop="dealDate"
          :label="$t('home_page_title17')"
          width="180"
        />
        <el-table-column
          prop="status"
          :label="$t('service_fee_query_title1')"
          width="180"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.row.status === 0
                  ? $t('service_fee_query_title2')
                  : scope.row.status === 1
                  ? $t('service_fee_query_title3')
                  : $t('service_fee_query_title4')
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          :label="$t('service_fee_query_title7')"
          width="140"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.row.type === 1
                  ? $t('service_fee_query_title8')
                  : $t('service_fee_query_title9')
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="fee"
          :label="$t('service_fee_query_title10')"
          width="140"
        />
        <el-table-column
          prop="updateTime"
          :label="$t('service_fee_query_title11')"
          width="180"
        />
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="pagination.total"
        style="margin-top: 20px"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { getServiceFeeQuery } from 'views/main/exchange-service/api';
import { PAGE_PARAMS } from '@/const/const';
export default {
  name: 'service-fee-query',
  data() {
    return {
      pagination: { ...PAGE_PARAMS },
      searchForm: { status: '', date: '' },
      tableData: [],
      loading: false,
    };
  },
  mounted() {
    this._getServiceFeeQueryList();
  },
  methods: {
    async _getServiceFeeQueryList() {
      const { pageSize, pageNum } = this.pagination;
      const { status, date } = this.searchForm;
      try {
        this.loading = true;
        const _params = {
          pageSize,
          pageNum,
          status,
          beginDate: date && date[0],
          endDate: date && date[1],
        };
        // 如果值为空则不传这个字段
        for (const k in _params) {
          if (
            _params[k] === '' ||
            _params[k] === undefined ||
            _params[k] === null
          ) {
            delete _params[k];
          }
        }
        let { dataSet, pages } = await getServiceFeeQuery(_params);
        this.tableData = dataSet;
        this.pagination = pages;
        this.loading = false;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    handleCurrentChange(val) {
      this.pagination = { ...this.pagination, pageNum: val };
      this._getServiceFeeQueryList();
    },
    handleStatusChange(val) {
      this.searchForm = Object.assign({}, this.searchForm, { status: val });
      this._getServiceFeeQueryList();
    },
    handleDateChange(val) {
      this.searchForm = Object.assign({}, this.searchForm, { date: val });
      this._getServiceFeeQueryList();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
