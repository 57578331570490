<template>
  <div class="initiate-exchange">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <p></p>
        <p>{{ $t('initlate_exchange_text1') }}</p>
      </div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="right"
        label-width="150px"
      >
        <el-form-item>{{ $t('initlate_exchange_text2') }}</el-form-item>
        <el-form-item
          :label="`${$t('credit_record_detail_msg4')}:`"
          prop="sell"
          style="margin-bottom: 0"
        >
          <el-select
            v-model="form.currency1"
            :placeholder="$t('incoming_company_select_placeholder')"
            @change="handleSelectChange"
            style="width: 180px"
          >
            <el-option v-for="c in sellList" :label="c" :key="c" :value="c" />
          </el-select>
        </el-form-item>
        <div class="el-i-box">
          <i class="el-icon-sort" @click="handleTransCurrency" />
        </div>
        <el-form-item :label="`${$t('credit_record_detail_msg5')}:`" prop="buy">
          <el-select
            v-model="form.currency2"
            :placeholder="$t('incoming_company_select_placeholder')"
            @change="handleSelectChange"
            style="width: 180px"
          >
            <el-option v-for="b in buyList" :label="b" :key="b" :value="b" />
          </el-select>
        </el-form-item>
        <el-form-item :label="`${$t('initlate_exchange_text3')}:`">
          <el-input disabled style="width: 180px" :value="my_rate" />
        </el-form-item>
        <el-form-item :label="`${$t('credit_record_detail_msg6')}:`">
          <el-date-picker
            type="date"
            disabled
            :placeholder="$t('incoming_company_select_placeholder')"
            v-model="form.date"
            style="width: 180px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          :label="`${$t('initlate_exchange_text4')}:`"
          prop="dealType"
        >
          <el-radio-group v-model="form.dealType" @change="handleCcyChange">
            <el-radio label="1">{{ $t('initlate_exchange_text5') }}</el-radio>
            <el-radio label="2">
              {{ $t('initlate_exchange_text6')
              }}{{
                this.limitData &&
                this['$utils'].formatAmount(this.limitData['balance'])
              }}）
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="`${$t('initlate_exchange_text7')}:`"
          prop="txnCcy"
        >
          <el-select
            v-model="form.txnCcy"
            @change="handleCcyChange"
            style="width: 200px"
            :disabled="itemDisabled"
            :placeholder="$t('COMMON_ERR4')"
          >
            <el-option
              :label="`${$t('credit_record_detail_msg4')}${
                this.form.currency1 || ''
              }`"
              :value="form.currency1"
            />
            <el-option
              :label="`${$t('credit_record_detail_msg5')}${
                this.form.currency2 || ''
              }`"
              :value="form.currency2"
            />
          </el-select>
          <el-input
            :disabled="itemDisabled"
            :placeholder="input_placeholder"
            oninput="value=value.replace(/[^\d.]/g,'')"
            v-model="form.txnAmount"
            style="width: 200px; margin-left: 4px"
            :max="12345"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click="handleNextStep"
        :loading="nextBtnLoading"
      >
        {{ $t('initlate_exchange_text8') }}
      </el-button>
    </el-card>
    <!--    确认报价弹窗-->
    <el-dialog
      center
      :title="$t('initlate_exchange_text9')"
      :visible.sync="nextVisible"
      :close-on-click-modal="false"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="nextVisible = false">
          {{ $t('system_settings_text15') }}
        </el-button>
        <el-button
          type="primary"
          @click="handleConfirmExchange"
          :disabled="timedone"
          :loading="confirmBtnLoading"
        >
          {{ $t('initlate_exchange_text10') }}
        </el-button>
      </span>
      <!--      内容-->
      <div class="confirm_content">
        <!--        需要根据本金币种判断，哪个是根据form.txtAmount,哪个是根据接口返回
        （例子：USD <=> EUR 如果单纯的把「卖出」显示为form.txtAmount就会发生「买入了200EUR，确认报价却是买入225.34EUR（其实这个是卖出USD的计算后价格」）
          新增函数this.calculateSellCurrency以及this.calculateBuyCurrency显示
        -->
        <p>
          {{ $t('credit_record_detail_msg4') }}：{{
            this.calculateSellCurrency('confirm')
          }}
        </p>
        <p>
          {{ $t('home_page_title21') }}：{{
            this.queryData && this.queryData.rate
          }}
        </p>
        <p>
          {{ $t('credit_record_detail_msg5') }}：{{
            this.calculateBuyCurrency('confirm')
          }}
        </p>
        <p>
          {{ $t('credit_record_detail_msg6') }}：{{
            this.form && this.form.date
          }}
        </p>
        <template v-if="this.form.dealType !== '1'">
          <p>
            {{ $t('initlate_exchange_text11') }}：
            {{ `${this.handleShowBalance()}` }}
          </p>
          <p>
            {{ $t('Remaining transaction amount') }}：{{
              `${this.handleShowRemainBalance()}`
            }}
          </p>
        </template>
        <p>
          {{ $t('initlate_exchange_text13') }}：
          {{ this.form && this.form.date }}
        </p>
      </div>
      <p>
        {{ timedone ? $t('initlate_exchange_text15') : message }}
      </p>
    </el-dialog>
    <!--      嵌套弹窗-->
    <el-dialog
      title="交易详情"
      :visible.sync="dealDetailVisible"
      center
      :close-on-click-modal="false"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dealDetailVisible = false">
          {{ $t('system_settings_text16') }}
        </el-button>
      </span>
      <div class="detail-box">
        <p>
          {{ $t('initlate_exchange_text16') }}：{{
            this.lockData && (this.lockData.uid || '-')
          }}
        </p>
        <p>
          {{ $t('initlate_exchange_text17') }}：{{
            this.lockData && Number(this.lockData.fee)
          }}
        </p>
        <p>
          {{ $t('credit_record_detail_msg3') }}：{{
            this.lockData && this.lockData.valueDate
          }}
        </p>
        <p>
          {{ $t('initlate_exchange_text18') }}：{{
            this.lockData && (this.lockData.tradeId || '-')
          }}
        </p>
        <p>
          {{ $t('credit_record_detail_msg4') }}：{{
            this.calculateSellCurrency('detail')
          }}
        </p>
        <p>
          {{ $t('credit_record_detail_msg5') }}：{{
            this.calculateBuyCurrency('detail')
          }}
        </p>
        <p>
          {{ $t('home_page_title21') }}：{{
            `${this.lockData && this.lockData.rate}`
          }}
        </p>
        <p>
          {{ $t('home_page_title22') }}：{{
            `${this.lockData && _getStatus(this.lockData.status)}`
          }}
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment/moment';
import {
  getQueryRate,
  getQueryLimit,
  getLockBooking,
} from 'views/main/exchange-service/api';
import numeral from 'numeral';

export default {
  data() {
    return {
      radio: 3,
      form: {
        date: moment(new Date()).format('YYYY-MM-DD'),
        txnCcy: '',
        dealType: '1',
        currency1: undefined,
        currency2: undefined,
        txnAmount: '',
      },
      limitData: null,
      nextVisible: false,
      nextBtnLoading: false,
      queryData: null,
      timecount: 15,
      timedone: false,
      timer: null,
      lockData: null, // 确定锁汇之后返回的数据
      dealDetailVisible: false,
      confirmBtnLoading: false, // 确认兑换loading
      currency_pair_operated: [],
      my_rate: '',
      amount_range: null,
      input_placeholder:
        this.$i18n.locale() === 'cn' ? '请输入金额' : 'Please enter the amount',
    };
  },
  mounted() {
    this._getQueryLimit();
  },
  methods: {
    _getStatus(status) {
      const arrStatus = [
        '未交割',
        '已交割',
        '交割失败',
        '已违约',
        '已受理',
        '交割中',
        '锁汇中',
        '锁汇失败',
      ];
      return arrStatus[status];
    },
    _cleanAmount() {
      this.form.txnCcy = '';
      this.form.txnAmount = '';
      this.input_placeholder =
        this.$i18n.locale() === 'cn' ? '请输入金额' : 'Please enter the amount';
    },
    // 点击下一步
    handleNextStep() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.form.currency1 === this.form.currency2) {
            this.$message.info('请选择不同的货币对!');
            return;
          }
          if (this.form.txnAmount && Number(this.form.txnAmount) === 0) {
            this.$message.warning('请输入金额');
            return;
          }
          // 查询可以输入的最大最小值
          if (
            Number(this.form.txnAmount) > this.amount_range.max ||
            Number(this.form.txnAmount) < this.amount_range.min
          ) {
            let { min, max, currency } = this.amount_range;

            if (currency === 'JPY') {
              min = numeral(min).format('0,0');
              max = numeral(max).format('0,0');
            }
            this.$message.warning({
              message: `请输入${min} ~ ${max} ${currency} 之间的金额！`,
              duration: 6000,
            });
            return;
          }
          const str = `${this.form.currency1}/${this.form.currency2}`;
          /*
            1.卖出USD买入CNH—用USDCNH找货币对—找到了 SELL
            卖出CNH买入USD—用CNHUSD找货币对—找不到—找USDCNH—找到了，BUY
            2.选择卖出USD.买入EUR, 货币对，USDEUR 找，没找到，找EURUSD，找到了， buy
          */
          let dealSite = ''; // 方向
          let res_str = ``; //最终货币对
          if (this.currency_pair_operated.indexOf(str) !== -1) {
            // 正向找到了
            dealSite = 'SELL';
            res_str = `${this.form.currency1}${this.form.currency2}`;
          }
          // 正向没找到找反向，反向找到了是BUY
          if (
            this.currency_pair_operated.indexOf(str) === -1 &&
            this.oppositeCurrencyPair.indexOf(str) !== -1
          ) {
            dealSite = 'BUY';
            res_str = `${this.form.currency2}${this.form.currency1}`;
          }
          // 正向没找到找反向，反向也没找到，警告没有此货币对
          if (
            this.currency_pair_operated.indexOf(str) === -1 &&
            this.oppositeCurrencyPair.indexOf(str) === -1
          ) {
            this.$message.warning('没有此货币对!');
            return;
          }
          // 接口
          const _params = {
            ccyPair: res_str,
            dealtSide: dealSite,
            txnAmount: Number(this.form.txnAmount),
            txnCcy: this.form.txnCcy,
          };
          this.timecount = 15;
          this.timedone = false;
          this.nextBtnLoading = true;
          try {
            this.queryData = await getQueryRate(_params);
            this.nextVisible = true;
          } catch (err) {
            console.error(err);
          } finally {
            this.nextBtnLoading = false;
          }
          this.$nextTick(() => {
            this.handleCountTime();
          });
        }
      });
    },
    handleCountTime() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.timecount--;
        if (this.timecount === 0) {
          this.timedone = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async _getQueryLimit() {
      try {
        this.limitData = await getQueryLimit();
      } catch (err) {
        console.error(err);
      }
    },
    // 转换回调fn
    handleTransCurrency() {
      if (!this.form.currency1 || !this.form.currency2) {
        this.$message.info('请先选择币种');
        return;
      }
      let temp = this.form.currency1;
      this.form.currency1 = this.form.currency2;
      this.form.currency2 = temp;
      this.form = Object.assign({}, this.form);
      this.handleSelectChange();
    },
    // 确认兑换
    async handleConfirmExchange() {
      const _params = {
        uid: this.queryData && this.queryData.uid,
        dealType: this.form && this.form.dealType,
      };
      try {
        this.confirmBtnLoading = true;
        this.lockData = await getLockBooking(_params);
        this.$message.success('兑换成功');
        this.timecount = 0;
        this.timedone = true;
        this.confirmBtnLoading = false;
        this.nextVisible = false;
        // 打开内嵌交易详情弹窗
        this.$nextTick(() => {
          this.dealDetailVisible = true;
        });
      } finally {
        this.confirmBtnLoading = false;
      }
    },
    // 模糊查询汇率回调
    handleQueryVagueRate(sell, buy) {
      this.$emit('getQueryCurrencyRate', { sell, buy });
    },
    // currency 1 change
    handleSelectChange() {
      if (this.form.currency1 && this.form.currency2) {
        this.$emit('cleanChangeInfo');
        // 查询汇率
        this.handleQueryVagueRate(this.form.currency1, this.form.currency2);
      }
      this._cleanAmount();
    },
    _formatJPY(str = '') {
      return this.form.txnCcy === 'JPY' ? numeral(str).format('0,0') : str;
    },
    // 本金币种改变fn
    handleCcyChange() {
      // 判断是现汇还是授信，changeInfo的min,max 是不一样的
      if (this.form.dealType === '1') {
        if (this.form.txnCcy === this.form.currency1) {
          // 当选入currency1卖出币种的时候
          this.input_placeholder = `${
            this.$i18n.locale() === 'cn' ? '最多卖出' : 'Sell out'
          }${this.changeInfo && this._formatJPY(this.changeInfo.max)}${
            this.form.txnCcy
          }`;
          // 更新范围rules
          this.amount_range = {
            currency: this.form.txnCcy,
            min: this.changeInfo && this.changeInfo.min,
            max: this.changeInfo && this.changeInfo.max,
          };
        }
        if (this.form.txnCcy === this.form.currency2) {
          // 当选择币种为买入币种的时候
          this.input_placeholder = `${
            this.$i18n.locale() === 'cn' ? '最多买入' : 'maximum buy-in'
          }${
            this.changeInfo && this._formatJPY(this.changeInfo['toMaxAmount'])
          }${this.form.txnCcy}`;
          // 更新范围rules
          this.amount_range = {
            currency: this.form.txnCcy,
            min: this.changeInfo && this.changeInfo['toMinAmount'],
            max: this.changeInfo && this.changeInfo['toMaxAmount'],
          };
        }
      }
      // 当选择交易方式为授信的时候
      if (this.form.dealType === '2') {
        // 当选入currency1卖出币种的时候
        if (this.form.txnCcy === this.form.currency1) {
          this.input_placeholder = `${
            this.$i18n.locale() === 'cn' ? '最多卖出' : 'Sell out'
          }${this.changeInfo && this._formatJPY(this.changeInfo['limitMax'])}${
            this.form.txnCcy
          }`;
          // 更新范围rules
          this.amount_range = {
            currency: this.form.txnCcy,
            min: this.changeInfo && this.changeInfo.min,
            max: this.changeInfo && this.changeInfo['limitMax'],
          };
        }
        if (this.form.txnCcy === this.form.currency2) {
          this.input_placeholder = `${
            this.$i18n.locale() === 'cn' ? '最多买入' : 'maximum buy-in'
          }${
            this.changeInfo && this._formatJPY(this.changeInfo['toLimitAmount'])
          }${this.form.txnCcy}`;
          // 更新范围rules
          this.amount_range = {
            currency: this.form.txnCcy,
            min: this.changeInfo && this.changeInfo.min,
            max: this.changeInfo && this.changeInfo['toLimitAmount'],
          };
        }
      }
    },
    // 确定「确认报价」中谁是卖出谁是买入？
    calculateSellCurrency(type) {
      if (type === 'confirm') {
        // 确认报价
        if (this.form.txnCcy === this.form.currency1) {
          return `${this.form.currency1}${this.form.txnAmount}`;
        }
        if (this.form.txnCcy === this.form.currency2) {
          return `${this.form.currency1}${
            this.queryData && this.queryData['contraAmount']
          }`;
        }
      }
      if (type === 'detail') {
        // 第三步查看详情
        if (this.form.txnCcy === this.form.currency1) {
          return `${this.lockData && this.lockData['txnCcy']}${
            this.form && this.form.txnAmount
          }`;
        }
        if (this.form.txnCcy === this.form.currency2) {
          return `${this.lockData && this.lockData['contraCcy']}${
            this.queryData && this.queryData['contraAmount']
          }`;
        }
      }
    },
    calculateBuyCurrency(type) {
      if (type === 'confirm') {
        if (this.form.txnCcy === this.form.currency1) {
          return `${this.form.currency2}${
            this.queryData && this.queryData['contraAmount']
          }`;
        }
        if (this.form.txnCcy === this.form.currency2) {
          return `${this.form.currency2}${this.form.txnAmount}`;
        }
      }
      if (type === 'detail') {
        `${this.lockData && this.lockData['contraCcy']}${
          this.lockData && this.lockData['contraAmount']
        } `;
        if (this.form.txnCcy === this.form.currency1) {
          return `${this.lockData && this.lockData['contraCcy']}${
            this.lockData && this.lockData['contraAmount']
          } `;
        }
        if (this.form.txnCcy === this.form.currency2) {
          return `${this.form.currency2}${this.form.txnAmount}`;
        }
      }
    },
    // 本次使用交易额度
    handleShowBalance() {
      // 卖出买入有美元就用美元，没有美元就用卖出币种金额进行折算
      if (this.form.currency1 === 'USD' || this.form.currency2 === 'USD') {
        return `USD${this.queryData && this.queryData['usdLimit']}`;
      }
      const value = this.queryData && this.queryData['usdLimit'];
      // 如果交易方式使用的是授信，那么交易额度都为 USD
      if (this.form.dealType === '2') {
        return `USD ${value}`;
      }
      return `${this.form.currency1}${value}`;
    },
    // 剩余交易额度
    handleShowRemainBalance() {
      // 卖出买入有美元就用美元，没有美元就用卖出币种金额进行折算，用授信额度减去所得数字
      if (this.form.currency1 === 'USD' || this.form.currency2 === 'USD') {
        return `USD${
          this.limitData &&
          this.queryData &&
          (
            Number(this.limitData['balance']) - this.queryData['usdLimit']
          ).toFixed(2)
        }`;
      }
      const value =
        this.limitData &&
        this.queryData &&
        (
          Number(this.limitData['balance']) - this.queryData['usdLimit']
        ).toFixed(2);
      // 如果交易方式使用的是授信，那么交易额度都为 USD
      if (this.form.dealType === '2') {
        return `USD ${value}`;
      }
      return `${this.form.currency1}${value}`;
    },
  },
  //  确认详情中谁是卖出谁是买入

  props: {
    sellList: { type: Array, default: () => [] },
    buyList: { type: Array, default: () => [] },
    currencyPair: { type: Array, default: () => [] },
    changeInfo: { type: Object, default: () => null },
    oppositeCurrencyPair: { type: Array, default: () => [] },
    itemDisabled: { type: Boolean, default: () => true },
  },
  watch: {
    currencyPair: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.currency_pair_operated = newValue.map(item => item.name.trim());
      },
    },
    changeInfo: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.my_rate = newValue && newValue.rate;
      },
    },
  },
  computed: {
    message() {
      if (this.$i18n.locale() === 'cn') {
        return `请在 ${this.timecount} 秒之内确认交易!`;
      } else {
        return `Please confirm your transaction within ${this.timecount} seconds!`;
      }
    },
    rules() {
      return {
        currency1: [{ required: true, message: this.$t('COMMON_ERR4') }],
        currency2: [{ required: true, message: this.$t('COMMON_ERR4') }],
        txnCcy: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        // dealtSide: [{ required: true }],
        txnAmount: [
          {
            required: true,
            message: this.$t('COMMON_ERR1'),
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  background-color: #fff;
}
.detail-box {
  p {
    margin-bottom: 14px !important;
  }
}
.initiate-exchange {
  width: 100%;
  height: 100%;
  display: flex;
  .box-card {
    margin-top: 20px;
    width: 100%;
    .clearfix {
      & > p:nth-child(2) {
        color: rgba(0, 0, 0, 0.35);
        font-size: 12px;
        padding-top: 4px;
      }
    }
    .el-i-box {
      width: 100%;
      position: relative;
      .el-icon-sort {
        margin-left: 230px;
        color: #1890ff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        display: inline-block;
        width: 18px;
        height: 18px;
        bottom: 1px;
        left: 70px;
        background: url('/images/sort-two.png') no-repeat center;
      }
    }
  }
}
.confirm_content {
  p {
    margin-bottom: 20px !important;
  }
}
</style>
