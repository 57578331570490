<template>
  <div class="exchangeService">
    <el-card class="my-card-home">
      <div class="main-type">
        <el-menu
          :default-active="curArea"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item
            index="1"
            @click="
              curArea = '1';
              _getConfigList();
            "
          >
            {{ $t('exchange_service_tab1') }}
          </el-menu-item>
          <el-menu-item
            index="2"
            @click="
              curArea = '2';
              _getConfigList();
            "
          >
            {{ $t('exchange_service_tab2') }}
          </el-menu-item>
          <el-menu-item
            index="3"
            @click="
              () => {
                curArea = '3';
              }
            "
          >
            {{ $t('exchange_service_tab3') }}
          </el-menu-item>
          <el-menu-item index="4" @click="curArea = '4'">
            {{ $t('exchange_service_tab4') }}
          </el-menu-item>
        </el-menu>
        <price-inquiry
          v-show="curArea === '1'"
          :sellList="sell_list"
          :buyList="buy_list"
          :origin-currency-pair="origin_currency_pair"
        />
        <initiate-exchange
          v-show="curArea === '2'"
          :currency-pair="origin_currency_pair"
          :sell-list="sell_list"
          :buy-list="buy_list"
          @getQueryCurrencyRate="_getQueryCurrencyRate"
          :change-info="change_info"
          @cleanChangeInfo="handleCleanChangeInfo"
          :opposite-currency-pair="opposite_currency_pair"
          :item-disabled="amount_item_disabled"
        />
        <contract-query v-if="curArea === '3'" />
        <service-fee-query v-if="curArea === '4'" />
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getConfigList,
  getQueryCurrencyRate,
} from 'views/main/exchange-service/api';
import PriceInquiry from './components/price-inquiry/price-inquiry';
import InitiateExchange from './components/initiate-exchange/initiate-exchange';
import ContractQuery from './components/contract-query/contract-query';
import ServiceFeeQuery from 'views/main/exchange-service/components/service-fee-query/service-fee-query';
export default {
  components: {
    PriceInquiry,
    InitiateExchange,
    ContractQuery,
    ServiceFeeQuery,
  },
  data() {
    return {
      curArea: '1',
      // tab1的属性
      buy_list: [], // 买入
      sell_list: [], // 卖出
      origin_currency_pair: [],
      vague_rate: '', // 模糊汇率
      change_info: null, // 换汇信息，包含max,min,rate
      opposite_currency_pair: [],
      amount_item_disabled: true, // tab2-发起兑换-等汇率查询之后才可以输入这个
    };
  },
  mounted() {
    this._getConfigList();
    // 如果是从首页点进来的，根据this.$route.query.order判断第几个
    if (this.$route.query.order) {
      this.curArea = this.$route.query.order;
    }
  },
  methods: {
    handleCleanChangeInfo() {
      this.change_info = null;
    },
    // 查询模糊汇率
    async _getQueryCurrencyRate({ sell, buy }) {
      const _params = {
        from: sell,
        to: buy,
      };
      this.amount_item_disabled = true;
      this.change_info = { ...this.change_info, rate: '查询中，请稍后...' };
      this.change_info = await getQueryCurrencyRate(_params);
      this.amount_item_disabled = false;
    },
    // tab1 - 获取币种列表
    async _getConfigList() {
      const _params = {
        typeId: '9',
      };
      this.origin_currency_pair = await getConfigList(_params);
      const currency_arr = [];
      // 正向货币对
      this.origin_currency_pair.forEach(c => {
        currency_arr.push({
          sell: c.name.split('/')[0].replace(/\s*/g, ''), // 后端返回的数据中有空格...
          buy: c.name.split('/')[1],
        });
      });
      const total_list = Array.from(
        new Set(
          currency_arr
            .map(obj => obj.sell)
            .concat(currency_arr.map(obj => obj.buy)),
        ),
      );
      this.sell_list = total_list;
      this.buy_list = total_list;
      // 反向货币对
      // 新增反转货币对-如果正向有那就是卖出，如果没有，查看反转货币对，如果能对上那就是买入
      const origin_copy = JSON.parse(JSON.stringify(this.origin_currency_pair));
      origin_copy.forEach(obj => {
        let str = obj.name;
        const str_arr = str.split('/');
        let temp = str_arr[0];
        str_arr[0] = str_arr[1];
        str_arr[1] = temp;
        obj.name = str_arr.join('/');
      });
      this.opposite_currency_pair = origin_copy.map(obj => obj.name);
    },
  },
};
</script>

<style scoped lang="scss">
.exchangeService {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 24px 0 24px;
  .my-card-home {
    margin-bottom: 10px;
    p {
      span {
        display: inline-block;
        width: 156px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.65);
        border-radius: 2px;
        margin-right: 32px;
        cursor: pointer;
      }
      span.active {
        color: white;
        border: none;
        background-color: #1890ff;
      }
    }
  }
}
</style>
