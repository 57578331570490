<template>
  <div class="ContractQuery">
    <el-card class="box-card" style="margin-top: 20px">
      <el-form ref="form" inline size="small" :model="searchForm">
        <el-form-item :label="`${$t('home_page_title22')}:`" prop="status">
          <el-select
            v-model="searchForm.status"
            :placeholder="$t('contract_query_title1')"
            @change="handleStatusChange"
          >
            <el-option
              v-for="(item, index) in statusArr"
              :label="item"
              :value="index + ''"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="`${$t('contract_query_title2')}:`"
          prop="actualEndStartDate"
        >
          <el-date-picker
            v-model="searchForm.actualEndStartDate"
            type="daterange"
            :range-separator="$t('incoming_company_date_placeholder1')"
            :start-placeholder="$t('incoming_company_date_placeholder2')"
            :end-placeholder="$t('incoming_company_date_placeholder3')"
            valueFormat="yyyy-MM-dd"
            format="yyyy-MM-dd"
            @change="handleDateChange"
            style="width: 400px"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="export" @click="handleExportContract">
        <i class="el-icon-upload"></i>
        <span>{{ $t('contract_query_title3') }}</span>
      </div>
      <!-- 表格 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        size="small"
        @selection-change="handleSelectionChange"
        v-loading="loading"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column
          prop="exDealType"
          :label="$t('contract_query_title4')"
          width="110"
        >
          <template slot-scope="scope">
            {{
              scope.row.exDealType === 1
                ? $t('initlate_exchange_text5')
                : $t('contract_query_title5')
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="ccyPair"
          :label="$t('contract_query_title6')"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="txnData"
          :label="$t('home_page_title19')"
          width="160"
        />
        <el-table-column
          prop="contraData"
          :label="$t('home_page_title20')"
          width="160"
        />
        <el-table-column
          prop="rate"
          :label="$t('home_page_title21')"
          width="160"
        />
        <el-table-column
          prop="valueDate"
          :label="$t('credit_record_detail_msg6')"
          width="160"
        />
        <el-table-column
          prop="dealDate"
          :label="$t('home_page_title17')"
          width="160"
        />
        <el-table-column
          prop="status"
          :label="$t('home_page_title22')"
          width="160"
        >
          <template slot-scope="scope">
            {{ changeStatusToTxt(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="feeData"
          :label="$t('initlate_exchange_text17')"
          width="160"
        />
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="pagination.total"
        style="margin-top: 20px"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  getContractQuery,
  getExportContract,
} from 'views/main/exchange-service/api';

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      pagination: { pageSize: 10, pageNum: 1, total: 0 },
      searchForm: {
        status: '',
        actualEndStartDate: ['', ''],
      },
      selectedRows: [],
    };
  },
  mounted() {
    this._getContractQuery(this.searchForm);
  },
  methods: {
    // 获取列表
    async _getContractQuery(params) {
      const _params = {
        ...this.pagination,
        status: this.searchForm.status || '',
        dealDateBefore: params.actualEndStartDate[0] || '',
        dealDateAfter: params.actualEndStartDate[1] || '',
      };
      delete _params['total'];
      !_params.status && delete _params.status;
      !_params.dealDateAfter && delete _params.dealDateAfter;
      !_params.dealDateBefore && delete _params.dealDateBefore;
      this.loading = true;
      let { dataSet, pages } = await getContractQuery(_params);
      this.loading = false;
      pages = { ...pages };
      this.tableData = dataSet;
      this.pagination = pages;
    },
    // 状态改变
    handleStatusChange(v) {
      this.searchForm.status = v;
      this._getContractQuery(this.searchForm);
    },
    // date change
    handleDateChange(v) {
      this.searchForm.actualEndStartDate = v;
      this._getContractQuery(this.searchForm);
    },
    handleCurrentChange(val) {
      this.pagination = { ...this.pagination, pageNum: val };
      this._getContractQuery(this.searchForm);
    },
    // 导出数据
    async handleExportContract() {
      const _params = {
        list: this.selectedRows,
      };
      await getExportContract(_params);
      this.$message.success('导出成功');
      this.handleSelectionChange([]);
    },
    // 多选选择fn
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
    changeStatusToTxt(v) {
      return this.statusArr[v] || '-';
    },
  },
  computed: {
    statusArr() {
      const language = {
        cn: [
          '未交割',
          '已交割',
          '交割失败',
          '已违约',
          '已受理',
          '交割中',
          '锁汇中',
          '锁汇失败',
        ],
        en: [
          'Undelivered',
          'Delivered',
          'Delivery failure',
          'Breach of contract',
          'Admissible',
          'Delivery',
          'Lockhart Exchange',
          'Failure to lock the exchange',
        ],
      };
      return language[this.$i18n.locale()];
    },
  },
};
</script>

<style scoped lang="scss">
.ContractQuery {
  width: 100%;
  height: 100%;
  display: flex;
  .box-card {
    width: 100%;
    .clearfix {
      & > p:nth-child(2) {
        color: rgba(0, 0, 0, 0.35);
        font-size: 12px;
        padding-top: 4px;
      }
    }
  }
  .export {
    cursor: pointer;
    float: right;
    color: #1890ff;
  }
  ::v-deep .has-gutter {
    background: #c00;
  }
}
</style>
