import request from '@/utils/request';

// 查询汇率（tab2)
export const getQueryRate = params =>
  request.post('/exchange-api/trade/quote', params);
// 查询配置列表
export const getConfigList = params =>
  request.post(`/setting-api/globalConfig/queryGlobalConfigList`, params);
// 批量查询
export const getQueryRateAll = params =>
  request.post(`/exchange-api/trade/quoteAll`, params);
// 获取额度
export const getQueryLimit = (params = {}) =>
  request.get(`/exchange-api/limit/get`, { params });
// 锁汇并交易
export const getLockBooking = params =>
  request.post(`/exchange-api/trade/booking`, params);
// 合约查询
export const getContractQuery = params =>
  request.get(`/exchange-api/trade/getDetail`, { params });
// 客户服务费查询
export const getServiceFeeQuery = params =>
  request.get(`/exchange-api/fee/getFee`, { params });
// 导出数据
export const getExportContract = params =>
  request.post(`/exchange-api/trade/export`, params, {
    responseType: 'blob',
  });
// 模糊查询汇率（批量和单独都是这一个）
export const getQueryCurrencyRate = params =>
  request.get(`/exchange-api/trade/rate`, { params });
