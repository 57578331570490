<template>
  <div class="container">
    <div class="button-box">
      <span>{{ $t('price_inquiry_text1') }}：</span>
      <el-button plain @click="handleAdd">
        {{ $t('incoming_beneficiary_form4') }}
      </el-button>
      <el-button plain @click="handlePatchQuery">
        {{ $t('price_inquiry_text2') }}
      </el-button>
      <el-button type="danger" @click="handleDelete">
        {{ $t('incoming_beneficiary_form5') }}
      </el-button>
    </div>
    <div class="box-container">
      <div
        class="item-box"
        v-for="(obj, idx) in count_arr"
        :key="`item-box-${idx}`"
      >
        <div class="item-title">
          <span>{{ $t('price_inquiry_text3') }}</span>
        </div>
        <el-form
          :ref="`form${idx}`"
          :model="obj"
          label-width="60px"
          :rules="rules"
        >
          <el-form-item
            :label="`${$t('credit_record_detail_msg4')}:`"
            size="medium"
            prop="sell"
          >
            <el-select
              v-model="obj.sell"
              :placeholder="$t('incoming_company_select_placeholder')"
            >
              <el-option v-for="c in sellList" :label="c" :key="c" :value="c" />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="`${$t('credit_record_detail_msg5')}:`"
            size="medium"
            prop="buy"
          >
            <el-select
              v-model="obj.buy"
              :placeholder="$t('incoming_company_select_placeholder')"
              style="width: 100%"
            >
              <el-option v-for="c in buyList" :label="c" :key="c" :value="c" />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="`${$t('credit_record_detail_msg5')}:`"
            size="medium"
          >
            <el-date-picker
              type="date"
              disabled
              :placeholder="$t('incoming_company_select_placeholder')"
              v-model="obj.date1"
              style="width: 180px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            :label="`${$t('home_page_title21')}:`"
            size="medium"
            label-width="110px"
          >
            <span>{{ obj.rate || '-' }}</span>
          </el-form-item>
          <el-button
            type="primary"
            style="margin-left: 30%"
            @click="() => handleSearch(idx)"
            :loading="obj.loading"
          >
            {{ $t('price_inquiry_text4') }}
          </el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getQueryCurrencyRate } from 'views/main/exchange-service/api';
import moment from 'moment';

export default {
  data() {
    return {
      current_target: null,
      count_arr: [
        {
          sell: undefined,
          buy: undefined,
          rate: '',
          loading: false, // btn loading状态
          date1: moment(new Date()).format('YYYY-MM-DD'),
        },
      ], // 所有item的数组
      btn_loading: false,
    };
  },
  props: {
    originCurrencyPair: {
      type: Array,
      default: () => [],
    },
    sellList: {
      type: Array,
      default: () => [],
    },
    buyList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleAdd() {
      if (this.count_arr.length > 9) {
        this.$message.info('不可再添加！');
        return;
      }
      this.count_arr.push({
        sell: undefined,
        buy: undefined,
        date1: new Date(),
        rate: '',
        loading: false,
      });
    },
    handleDelete() {
      if (this.count_arr.length === 1) {
        this.$message.info('不可再删除!');
        return;
      }
      this.count_arr.pop();
    },
    /* 卖出和买入中，选了一个另一个就不能再选了*/
    // 单个查汇率
    handleSearch(idx) {
      this.$refs[`form${idx}`][0].validate(async valid => {
        const target = this.count_arr[idx];
        if (target.sell === target.buy) {
          this.$message.info('请选择不同的货币对');
          return;
        }
        const _params = {
          from: target.sell,
          to: target.buy,
        };
        if (valid) {
          try {
            target.loading = true;
            this.count_arr = [...this.count_arr];
            const res = await getQueryCurrencyRate(_params);
            target.rate = res && res['rate'];
            target.loading = false;
            this.count_arr = [...this.count_arr];
          } catch (err) {
            console.error(err);
          } finally {
            target.loading = false;
            this.count_arr = [...this.count_arr];
          }
        } else {
          return false;
        }
      });
    },
    // 批量查询
    handlePatchQuery() {
      for (let idx = 0; idx < this.count_arr.length; idx++) {
        this.handleSearch(idx);
      }
    },
  },
  computed: {
    rules() {
      return {
        sell: [{ required: true, message: this.$t('COMMON_ERR1') }],
        buy: [{ required: true, message: this.$t('COMMON_ERR1') }],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .button-box {
    margin-top: 20px;
    border-radius: 30px;
    .my-btn {
      margin-right: 10px;
    }
  }
  .box-container {
    display: flex;
    flex-wrap: wrap;
    .item-title {
      margin-bottom: 10px;
    }
    .item-box {
      border: 1px dashed #ccc;
      max-width: 270px;
      box-sizing: border-box;
      padding: 10px;
      margin: 10px;
    }
  }
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  margin-top: 10px;
  width: 480px;
}

::v-deep .el-form-item--mini {
  margin-bottom: 14px;
}
</style>
